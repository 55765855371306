import React from "react";
import MainTitle from "../views/main-title";
import styled from "styled-components";
import { theme, adminCompanyName } from "../../configurations/general-conf";
import ComboBox from "../widgets/combo-box";
import UploadBox from "../widgets/upload-box";
import DateBox from "../widgets/date-box";
import CascadeCollection from "../widgets/cascade-collection";
import * as listApi from "../../api/list-api";
import axios from "axios";
import _ from "lodash";
import * as restful from "../../api/restful-api";
import store from "../../store";
// import ReachTextEditor from '../widgets/reach-text-editor';

const BasicInfoContainer = styled.div`
  float: left;
  background-color: ${(props) =>
    props.bgColor ? theme[props.bgColor] : theme.mainBgColor};
  height: ${(props) => (props.heightPx ? props.heightPx + "px" : "")};
  width: ${(props) =>
    props.widthPercentage ? props.widthPercentage + "%" : "100%"};
`;

const BasicInfoTitle = styled.h2`
  font-size: 20px;
  color: ${theme.mainDeepColor};
  margin: 30px auto;
  text-align: center;
`;

const BasicInfoField = styled.li`
  margin: 10px auto;
  width: 86.667%;
  background-color: #fff;

  & input {
    border: none;
    color: ${theme.mainLighterColor};
    padding: 0 10px 0 10px;
    height: 38px;
    font-size: 14px;
    width: 60%;
  }

  & textarea {
    border: 0;
    color: ${theme.mainLighterColor};
    padding: 10px 0 0 10px;
    font-size: 14px;
    width: 60%;
  }
`;

const BasicInfoLabel = styled.label`
  font-size: 14px;
  color: ${(props) =>
    props.invalid ? theme.failureColor : theme.mainDeepColor};
  display: block;
  width: 40%;
  height: 100%;
  float: left;
  padding-right: 2px;
  text-align: right;
  line-height: 38px;
`;

const BasicInfoFieldCombo = styled.li`
  margin: 10px auto;
  width: 86.667%;
  background-color: #fff;
  height: 38px;
`;

const ComboBoxContainer = styled.div`
  width: 60%;
  float: left;
`;

const PromptInfo = styled.p`
  width: 86.667%;
  margin: auto;
  line-height: 1.5;
  text-align: center;
`;

const MandatoryMark = styled.span`
  color: ${theme.failureColor};
`;

const BottomButtonsContainer = styled.div`
  float: right;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const BottomButton = styled.button`
  height: 38px;
  width: 120px;
  border: none;
  padding: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
  background-color: ${(props) =>
    props.submitChange
      ? theme.mainLighterColor
      : props.cancelChange
      ? theme.failureColor
      : theme.mainDarkColor};

  &:hover {
    background-color: ${(props) =>
      props.submitChange
        ? theme.mainLightColor
        : props.cancelChange
        ? theme.failureLightColor
        : theme.mainDarkColorHover};
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveButtonDisabled: false,
      showSaveAsButton: false,
      renderSpecial: false
    };
  }

  async componentDidMount() {
    const currentPath = window.location.pathname;
    if (currentPath.indexOf("/edit/") > -1) {
      const { endPoint } = this.props.conf;
      const { listItemId } = this.props.match.params;
      const res = await axios.get(endPoint + "/" + listItemId);
      const listItem = res.data.objs ? res.data.objs[0] : null;
      // console.log(listItem);
      const isControlled = this.props.conf.singleItem?.basicInfo?.controlDisable?.opt.includes(listItem[this.props.conf.singleItem?.basicInfo?.controlDisable?.key]);

      if (listItem && this.props.conf.singleItem) {
        this.props.conf.singleItem.basicInfo.fields.map(async (field) => {
          // if(isControlled && field.controlDisable) {
          //   field.controlHide = true;
          // }
          let fieldDisabled = (isControlled && field.controlDisable) || (field.canUpdate===false);
          if(fieldDisabled&&field.testKey){
            fieldDisabled = field.exOpt.includes(listItem[field.testKey]);
          }
          if(fieldDisabled&&field.sUsers){
            fieldDisabled = !field.sUsers.some(element => {
              return element.toLowerCase() === restful.getCookie("userName").toLowerCase();
            });
          }
          switch (field.type) {
            case "checkbox":
              let checked = false;
              if (listItem[field.name] === 1) {
                checked = true;
              }
              this.refs[field.name].checked = checked;
              this.refs[field.name].disabled = fieldDisabled;
              break;
            case "combo":
              if(field.limitShowKey){
                // field.render = listItem[field.limitShowKey] === field.limitShowValue;
                this.setState({renderSpecial: listItem[field.limitShowKey] === field.limitShowValue});
              }
              if ((field.conf.endPoint && !field.limitShowKey) || (field.limitShowKey && listItem[field.limitShowKey] === field.limitShowValue)) {
                const res = await axios.get(
                  field.conf.endPoint.url + "/" + listItem[field.name]
                );
                if (res.data.objs.length !== 0) {
                  this.refs[field.name].setState({
                    value: {
                      value: listItem[field.name],
                      label: res.data.objs[0][field.conf.endPoint.lableKey],
                    },
                    isDisabled: fieldDisabled
                  });
                }else{
                  this.refs[field.name].setState({
                    isDisabled: fieldDisabled
                  });
                }
              } else {
                field.conf.staticOptions && field.conf.staticOptions.map((opt) => {
                  if (opt.value === listItem[field.name]) {
                    this.refs[field.name].setState({ value: opt, isDisabled: fieldDisabled });
                  }
                  return null;
                });
              }
              break;
            case "date":
              this.refs[field.name].setState({ value: listItem[field.name], disabled: fieldDisabled });
              break;
            default:
              this.refs[field.name].value = listItem[field.name];
              this.refs[field.name].disabled = fieldDisabled;
              break;
          }
          return null;
        });
      }

      if (
        this.props.conf.singleItem &&
        this.props.conf.singleItem.mediaFile &&
        listItem[this.props.conf.singleItem.mediaFile.name]
      ) {
        this.refs[this.props.conf.singleItem.mediaFile.name].setState({
          uploadedFileName: listItem[this.props.conf.singleItem.mediaFile.name],
          showSelectButton: false,
          showRemoveButton: true,
        });
      }
      if (
        this.props.conf.singleItem &&
        this.props.conf.singleItem.mediaFile1 &&
        listItem[this.props.conf.singleItem.mediaFile1.name]
      ) {
        this.refs[this.props.conf.singleItem.mediaFile1.name].setState({
          uploadedFileName:
            listItem[this.props.conf.singleItem.mediaFile1.name],
          showSelectButton: false,
          showRemoveButton: true,
        });
      }
      if (
        this.props.conf.singleItem &&
        this.props.conf.singleItem.mediaFile2 &&
        listItem[this.props.conf.singleItem.mediaFile2.name]
      ) {
        this.refs[this.props.conf.singleItem.mediaFile2.name].setState({
          uploadedFileName:
            listItem[this.props.conf.singleItem.mediaFile2.name],
          showSelectButton: false,
          showRemoveButton: true,
        });
      }
      if (
        this.props.conf.singleItem &&
        this.props.conf.singleItem.mediaFile3 &&
        listItem[this.props.conf.singleItem.mediaFile3.name]
      ) {
        this.refs[this.props.conf.singleItem.mediaFile3.name].setState({
          uploadedFileName:
            listItem[this.props.conf.singleItem.mediaFile3.name],
          showSelectButton: false,
          showRemoveButton: true,
        });
      }

      if (this.props.conf.singleItem.cascadeCollection) {
        const hideCascade = this.props.conf.singleItem.cascadeCollection.controlDisable && isControlled;
        if (
          this.props.conf.singleItem &&
          this.props.conf.singleItem.cascadeCollection &&
          !hideCascade
        ) {
            let { endPointMap, endPointFilter } =
              this.props.conf.singleItem.cascadeCollection;
            if (endPointMap.indexOf(":uuid") > -1) {
              endPointMap = endPointMap.replace(
                ":uuid",
                listItem[endPointFilter.valueKey]
              );
            }
            const resdataList = await axios.get(endPointMap);
            //console.log(resdataList.data);
            const collectedData =
              listItem[this.props.conf.singleItem.cascadeCollection.name];
            //console.log(typeof collectedData[0]);
            if (resdataList.data.objs) {
              let showMainUuids = [];
              let dataList = [];
              resdataList.data.objs.map((obj) => {
                let subDataList = [];
                obj[this.props.conf.singleItem.cascadeCollection.conf.subListKey] &&
                  obj[
                    this.props.conf.singleItem.cascadeCollection.conf.subListKey
                  ].map((subObj) => {
                    if (
                      collectedData?.indexOf(
                        subObj[
                          this.props.conf.singleItem.cascadeCollection.conf
                            .subListFields[0].valueKey
                        ]
                      ) > -1
                    ) {
                      if (
                        showMainUuids.indexOf(
                          obj[
                            this.props.conf.singleItem.cascadeCollection.conf
                              .mainCat.valueKey
                          ]
                        ) < 0
                      ) {
                        showMainUuids.push(
                          obj[
                            this.props.conf.singleItem.cascadeCollection.conf
                              .mainCat.valueKey
                          ]
                        );
                      }
                    }

                    const objTemp = _.filter(
                      collectedData,
                      (item) =>
                        item[
                          this.props.conf.singleItem.cascadeCollection.conf
                            .subListFields[0].valueKey
                        ] ===
                        subObj[
                          this.props.conf.singleItem.cascadeCollection.conf
                            .subListFields[0].valueKey
                        ]
                    );
                    if (objTemp.length) {
                      if (
                        showMainUuids.indexOf(
                          obj[
                            this.props.conf.singleItem.cascadeCollection.conf
                              .mainCat.valueKey
                          ]
                        ) < 0
                      ) {
                        showMainUuids.push(
                          obj[
                            this.props.conf.singleItem.cascadeCollection.conf
                              .mainCat.valueKey
                          ]
                        );
                      }
                    }

                    if (endPointFilter) {
                      if (
                        subObj[endPointFilter.conditionKey] ===
                        endPointFilter.conditionValue
                      ) {
                        subDataList.push(subObj);
                      }
                    } else {
                      subDataList.push(subObj);
                    }

                    return null;
                  });
                obj[this.props.conf.singleItem.cascadeCollection.conf.subListKey] =
                  subDataList;
                if (subDataList.length) {
                  dataList.push(obj);
                }
                return null;
              });
              //console.log(resdataList.data.objs);
              //console.log(dataList);
              this.refs[this.props.conf.singleItem.cascadeCollection.name].setState(
                {
                  checkedUuids:
                    typeof collectedData?.[0] === "string" ? collectedData : [],
                  dataCollection:
                    typeof collectedData?.[0] === "object" ? collectedData : [],
                  dataList: dataList,
                  showMainUuids,
                }
              );
            }
          }
      }
    } else {
      this.props.conf.singleItem.basicInfo.fields.map(async (field) => {
        switch (field.type) {
            case "date":
              if (field.delayStart) {
                var date = new Date(); // Now
                date.setDate(date.getDate() + field.delayStart); // Set now + 30 days as the new date
                date.setHours(0,0,0,0);
                this.refs[field.name].setState({ value: date});
              }
              break;
            default:
              break;
          }
         return null;
      });
      if (
        this.props.conf.singleItem &&
        this.props.conf.singleItem.cascadeCollection &&
        this.props.conf.singleItem.cascadeCollection.endPointMap.indexOf(
          ":uuid"
        ) === -1
      ) {
        const resdataList = await axios.get(
          this.props.conf.singleItem.cascadeCollection.endPointMap
        );
        if (resdataList.data.objs) {
          let showMainUuids = [];
          if (
            this.props.conf.singleItem.cascadeCollection
              .initialSubListDisplay === "all"
          ) {
            resdataList.data.objs.map((obj) => {
              return showMainUuids.push(
                obj[
                  this.props.conf.singleItem.cascadeCollection.conf.mainCat
                    .valueKey
                ]
              );
            });
          } else if (
            this.props.conf.singleItem.cascadeCollection
              .initialSubListDisplay === "first"
          ) {
            if (resdataList.data.objs.length) {
              showMainUuids.push(
                resdataList.data.objs[0][
                  this.props.conf.singleItem.cascadeCollection.conf.mainCat
                    .valueKey
                ]
              );
            }
          }

          this.refs[this.props.conf.singleItem.cascadeCollection.name].setState(
            {
              dataList: resdataList.data.objs,
              showMainUuids,
            }
          );
        }
      }
    }
  }

  uploadingControl = (isUploading) => {
    this.setState({
      saveButtonDisabled: isUploading,
    });
  };

  saveChange = (mode) => {
    let listItem = { isValid: [] };
    this.props.conf.singleItem.basicInfo.fields.map((field) => {
      switch (field.type) {
        case "checkbox":
          const checked = this.refs[field.name].checked;
          if (checked) {
            listItem[field.name] = 1;
          } else {
            listItem[field.name] = 0;
          }
          break;
        case "combo":
          if(!field.limitShowKey || this.state.renderSpecial){
            const comboValue = this.refs[field.name].state.value;
            if (comboValue) {
              listItem[field.name] = comboValue.value;
              delete field.invalid;
            } else if (field.mandatory && !comboValue) {
              field.invalid = true;
              listItem.isValid.push(1);
            } 
            if (field.avoidDupField && !field.invalid) {
              const avoidDupValue = this.refs[field.avoidDupField].state.value;
              console.log(avoidDupValue);
              console.log(comboValue);
              if (avoidDupValue && avoidDupValue.value === comboValue.value) {
                field.invalid = true;
                listItem.isValid.push(1);
              }
            }
          }
          break;
        case "date":
          const dateValue = this.refs[field.name].state.value;
          // console.log(dateValue);
          const dateValueAdjusted = new Date(dateValue);
          dateValueAdjusted.setHours(12);
          // console.log(dateValueAdjusted);
          if (dateValue) {
            listItem[field.name] = dateValueAdjusted;
            delete field.invalid;
          } else if (field.mandatory && !dateValue) {
            field.invalid = true;
            listItem.isValid.push(1);
          }
          break;
        default:
          const value = this.refs[field.name].value.replace("'", "’");
          if(field.reg && !new RegExp(field.reg).test(value)) {
            field.invalid = true;
            listItem.isValid.push(1);
          } else if (value) {
            listItem[field.name] = value;
            delete field.invalid;
          } else if (field.mandatory && !value) {
            field.invalid = true;
            listItem.isValid.push(1);
          }
          // if (field.reg){
          //   const reg = new RegExp(field.reg);
          //   if (!reg.test(value)){
          //     field.invalid = true;
          //     listItem.isValid.push(1);
          //   }
          // }
          break;
      }
      return null;
    });
    this.setState({});

    const { mediaFile } = this.props.conf.singleItem;
    if (mediaFile) {
      const { uploadedFileName } = this.refs[mediaFile.name].state;
      if (uploadedFileName) {
        listItem[mediaFile.name] = uploadedFileName;
      }
    }

    const { mediaFile1 } = this.props.conf.singleItem;
    if (mediaFile1) {
      const { uploadedFileName } = this.refs[mediaFile1.name].state;
      if (uploadedFileName) {
        listItem[mediaFile1.name] = uploadedFileName;
      }
    }

    const { mediaFile2 } = this.props.conf.singleItem;
    if (mediaFile2) {
      const { uploadedFileName } = this.refs[mediaFile2.name].state;
      if (uploadedFileName) {
        listItem[mediaFile2.name] = uploadedFileName;
      }
    }

    const { mediaFile3 } = this.props.conf.singleItem;
    if (mediaFile3) {
      const { uploadedFileName } = this.refs[mediaFile3.name].state;
      if (uploadedFileName) {
        listItem[mediaFile3.name] = uploadedFileName;
      }
    }

    const { cascadeCollection } = this.props.conf.singleItem;
    if (cascadeCollection) {
      const { checkedUuids, dataCollection } =
        this.refs[cascadeCollection.name].state;
      listItem[cascadeCollection.name] = dataCollection.length
        ? dataCollection
        : checkedUuids;
    }

    if (!listItem.isValid.length) {
      delete listItem.isValid;
      this.refs["saveChangeButton"].disabled = true;
      const currentPath = window.location.pathname;
      //console.log(listItem);
      if (currentPath.indexOf("/new") > -1 || mode==='saveAs') {
        const resultMsg = listApi.createListItem(this.props, listItem);
        resultMsg.then((msg) => {
          const { nameSpace } = this.props.conf;
          const newUuid = msg[nameSpace + 'Uuid'];
          if (newUuid && (store.getState()[nameSpace].extensionSelection.indexOf(newUuid)>-1)===false) {
            setTimeout(() => store.dispatch({type: 'add_' + nameSpace + '_extension', listItemId: newUuid}), 500);
          }
          if (msg === "error") {
            this.refs["saveChangeButton"].disabled = false;
          }
        });
      } else if (currentPath.indexOf("/edit/") > -1) {
        const resultMsgEdit = listApi.updateListItem(
          this.props,
          listItem,
          this.props.match.params.listItemId
        );
        resultMsgEdit.then((msg) => {
          if (msg === "error") {
            this.refs["saveChangeButton"].disabled = false;
          }
        });
      }
    }
  };

  cancelChange = () => {
    const currentPath = window.location.pathname;
    if (currentPath.indexOf("/new") > -1) {
      const backPath = currentPath.replace("/new", "");
      this.props.history.push(backPath);
    } else if (currentPath.indexOf("/edit/") > -1) {
      const backPath = this.props.match.path.replace("/edit/:listItemId", "");
      this.props.history.push(backPath);
    }
  };

  enableSaveAs = () => {
    if(!this.state.showSaveAsButton){
      this.setState({showSaveAsButton: true});
    }
  };

  syncChange = (value) => {
    // console.log(value);
    const { endPointMap, endPointFilter, conf, name, initialSubListDisplay } =
      this.props.conf.singleItem.cascadeCollection;
    axios.get(endPointMap.replace(":uuid", value)).then((resdataList) => {
      if (resdataList.data.objs) {
        let showMainUuids = [];
        let dataList = [];

        resdataList.data.objs.map((obj) => {
          let subDataList = [];
          obj[conf.subListKey] &&
            obj[conf.subListKey].map((subObj) => {
              if (endPointFilter) {
                if (
                  subObj[endPointFilter.conditionKey] ===
                  endPointFilter.conditionValue
                ) {
                  subDataList.push(subObj);
                }
              } else {
                subDataList.push(subObj);
              }

              return null;
            });
          obj[conf.subListKey] = subDataList;
          if (subDataList.length) {
            dataList.push(obj);
            if (initialSubListDisplay === "all") {
              showMainUuids.push(obj[conf.mainCat.valueKey]);
            }
          }
          return null;
        });

        if (initialSubListDisplay === "first" && dataList.length) {
          showMainUuids.push(dataList[0][conf.mainCat.valueKey]);
        }

        this.refs[name].setState({
          dataList: dataList,
          showMainUuids,
        });
      }
    });
  };

  syncCategoryChange = (value, syncRef) => {
    const { endPointUrl, valueKey, labelKey } =
      this.props.conf.singleItem.categorySyncConf;
    if (this.refs['customerUuid'].state.value === null) {
      window.alert("Please select Customer first.");
      return null;
    }
    const cUuid = this.refs['customerUuid'].state.value.value;

    axios.get( endPointUrl.replace(":cusuuid/:catuuid", cUuid+"/"+value)).then((resdataList) => {
      if (resdataList.data.objs) {
        let options = [];

        resdataList.data.objs.map((obj) => {
          options.push({ value: obj[valueKey], label: obj[labelKey] });
          return null;
        });

        this.refs[syncRef].setState({ options });
        this.refs[syncRef].setState({value: ""});
      }
    });
  };

  clearCategoryChange = (syncRef) => {
    this.refs[syncRef].setState({ options: [{value: "",label: ""}] });
    this.refs[syncRef].setState({value: ""});
  };

  syncProCatChange = (value, syncRef) => {
    const { endPointUrl, valueKey, labelKey } =
      this.props.conf.singleItem.proCatSyncConf;

    axios.get( endPointUrl.replace(":productCategoryUuid", value)).then((resdataList) => {
      if (resdataList.data.objs) {
        let options = [];

        resdataList.data.objs.map((obj) => {
          options.push({ value: obj[valueKey], label: obj[labelKey] });
          return null;
        });

        this.refs[syncRef].setState({ options });
        this.refs[syncRef].setState({value: ""});
      }
    });
  };

  clearProCatChange = (syncRef) => {
    this.refs[syncRef].setState({ options: [{value: "",label: ""}] });
    this.refs[syncRef].setState({value: ""});
  };

  syncCustomerChange = (value, syncRef) => {
    const { endPointUrl, valueKey, labelKey } =
      this.props.conf.singleItem.customerSyncConf;
    axios.get( endPointUrl.replace(":uuid", value)).then((resdataList) => {
      if (resdataList.data.objs) {
        let options = [];

        resdataList.data.objs.map((obj) => {
          options.push({ value: obj[valueKey], label: obj[labelKey] });
          return null;
        });

        this.refs[syncRef+'1'].setState({ options });
        this.refs[syncRef+'1'].setState({value: ""});

        this.refs[syncRef+'2'].setState({ options });
        this.refs[syncRef+'2'].setState({value: ""});

        this.refs[syncRef+'2'].setState({ options });
        this.refs[syncRef+'2'].setState({value: ""});

        this.refs[syncRef+'3'].setState({ options });
        this.refs[syncRef+'3'].setState({value: ""});

        this.refs[syncRef+'4'].setState({ options });
        this.refs[syncRef+'4'].setState({value: ""});

        this.refs[syncRef+'5'].setState({ options });
        this.refs[syncRef+'5'].setState({value: ""});
      }
    });
  };

  clearCustomerChange = (syncRef) => {
    this.refs[syncRef+'1'].setState({ options: [{value: "",label: ""}] });
    this.refs[syncRef+'1'].setState({value: ""});
    this.refs['productUuid1'].setState({ options: [{value: "",label: ""}] });
    this.refs['productUuid1'].setState({value: ""});

    this.refs[syncRef+'2'].setState({ options: [{value: "",label: ""}] });
    this.refs[syncRef+'2'].setState({value: ""});
    this.refs['productUuid2'].setState({ options: [{value: "",label: ""}] });
    this.refs['productUuid2'].setState({value: ""});

    this.refs[syncRef+'3'].setState({ options: [{value: "",label: ""}] });
    this.refs[syncRef+'3'].setState({value: ""});
    this.refs['productUuid3'].setState({ options: [{value: "",label: ""}] });
    this.refs['productUuid3'].setState({value: ""});

    this.refs[syncRef+'4'].setState({ options: [{value: "",label: ""}] });
    this.refs[syncRef+'4'].setState({value: ""});
    this.refs['productUuid4'].setState({ options: [{value: "",label: ""}] });
    this.refs['productUuid4'].setState({value: ""});

    this.refs[syncRef+'5'].setState({ options: [{value: "",label: ""}] });
    this.refs[syncRef+'5'].setState({value: ""});
    this.refs['productUuid5'].setState({ options: [{value: "",label: ""}] });
    this.refs['productUuid5'].setState({value: ""});
  };

  render() {
    const { title, singleItem } = this.props.conf;
    //const nameSpaceTitle = nameSpace.charAt(0).toUpperCase() + nameSpace.slice(1);
    const pageSwitch =
      window.location.href.indexOf("/new") > -1 ? "Create" : "Update";
    const mainTitle = pageSwitch + " " + title;
    let heightPx = null;
    if (pageSwitch === "Create") {
      heightPx = singleItem.basicInfo.createHeightPx ? singleItem.basicInfo.createHeightPx : singleItem.basicInfo.heightPx;
    } else {
      heightPx = singleItem.basicInfo.heightPx
    }
    return (
      <React.Fragment>
        <MainTitle title={mainTitle} />
        {singleItem && singleItem.basicInfo ? (
          <BasicInfoContainer
            heightPx={heightPx}
            widthPercentage={singleItem.basicInfo.widthPercentage}
            bgColor={singleItem.basicInfo.bgColor}
          >
            <BasicInfoTitle>
              {singleItem.basicInfo.title
                ? singleItem.basicInfo.title
                : "Basic Information"}
            </BasicInfoTitle>
            <ul>
              {singleItem.basicInfo.fields.map((field, i) => {
                const disabledInUpdate =
                  pageSwitch === "Update" && field.canUpdate === false
                    ? true
                    : false;
                let displayField = true;
                let showField = true;
                if (field.adminCompanyOnly === true) {
                  displayField =
                    adminCompanyName === restful.getCookie("companyName")
                      ? true
                      : false;
                }
                if (field.hiddenInCreate === true && pageSwitch === "Create") {
                  displayField = false;
                }
                if (field.limitShowKey) {
                  showField = this.state.renderSpecial;
                  // console.log(showField);
                }
                switch (field.type) {
                  case "checkbox":
                    return (
                      <BasicInfoField
                        key={i}
                        style={displayField ? {} : { display: "none" }}
                      >
                        <BasicInfoLabel invalid={field.invalid}>
                          {field.mandatory ? (
                            <MandatoryMark>*</MandatoryMark>
                          ) : null}
                          {field.label}:
                        </BasicInfoLabel>
                        <input
                          disabled={disabledInUpdate}
                          type="checkbox"
                          ref={field.name}
                          defaultChecked={field.defaultChecked ? true : false}
                        />
                      </BasicInfoField>
                    );
                  case "combo":
                    return (
                      showField && <BasicInfoFieldCombo
                        key={i}
                        style={displayField ? {} : { display: "none" }}
                      >
                        <BasicInfoLabel invalid={field.invalid}>
                          {field.mandatory ? (
                            <MandatoryMark>*</MandatoryMark>
                          ) : null}
                          {field.label}:
                        </BasicInfoLabel>
                        <ComboBoxContainer>
                          <ComboBox
                            disabled={disabledInUpdate}
                            conf={field.conf}
                            ref={field.name}
                            syncChange={this.syncChange}
                            syncCategoryChange={this.syncCategoryChange}
                            clearCategoryChange={this.clearCategoryChange}
                            syncCustomerChange={this.syncCustomerChange}
                            clearCustomerChange={this.clearCustomerChange}
                            syncProCatChange={this.syncProCatChange}
                            clearProCatChange={this.clearProCatChange}
                          />
                        </ComboBoxContainer>
                      </BasicInfoFieldCombo>
                    );
                  case "text":
                    return (
                      <BasicInfoField
                        key={i}
                        style={displayField ? {} : { display: "none" }}
                      >
                        <BasicInfoLabel invalid={field.invalid}>
                          {field.mandatory ? (
                            <MandatoryMark>*</MandatoryMark>
                          ) : null}
                          {field.label}:
                        </BasicInfoLabel>
                        <input
                          disabled={disabledInUpdate}
                          type={field.inputType ? field.inputType : "text"}
                          autoComplete={field.inputType === "password" ? "new-password" : null}
                          ref={field.name}
                          maxLength={field.maxLength ? field.maxLength : null}
                          placeholder={field.placeholder ? field.placeholder : null}
                          onChange={field.saveAs && pageSwitch==='Update' ? this.enableSaveAs : null}
                        />
                      </BasicInfoField>
                    );
                  case "number":
                    return (
                      <BasicInfoField
                        key={i}
                        style={displayField ? {} : { display: "none" }}
                      >
                        <BasicInfoLabel invalid={field.invalid}>
                          {field.mandatory ? (
                            <MandatoryMark>*</MandatoryMark>
                          ) : null}
                          {field.label}:
                        </BasicInfoLabel>
                        <input
                          disabled={disabledInUpdate}
                          type="number"
                          ref={field.name}
                          max={field.max ? field.max : null}
                          min={field.min ? field.min : null}
                          placeholder={field.placeholder ? field.placeholder : null}
                          maxLength={field.maxLength ? field.maxLength : null}
                          step={field.step ? field.step : null}
                          onChange={field.saveAs && pageSwitch==='Update' ? this.enableSaveAs : null}
                        />
                      </BasicInfoField>
                    );
                  case "date":
                    return (
                      <BasicInfoFieldCombo
                        key={i}
                        style={displayField ? {} : { display: "none" }}
                      >
                        <BasicInfoLabel invalid={field.invalid}>
                          {field.mandatory ? (
                            <MandatoryMark>*</MandatoryMark>
                          ) : null}
                          {field.label}:
                        </BasicInfoLabel>
                        <ComboBoxContainer>
                          <DateBox
                            disabled={disabledInUpdate}
                            conf={field.conf}
                            ref={field.name}
                          />
                        </ComboBoxContainer>
                      </BasicInfoFieldCombo>
                    );
                  case "multiText":
                    return (
                      <BasicInfoField
                        key={i}
                        style={displayField ? {} : { display: "none" }}
                      >
                        <BasicInfoLabel invalid={field.invalid}>
                          {field.mandatory ? (
                            <MandatoryMark>*</MandatoryMark>
                          ) : null}
                          {field.label}:
                        </BasicInfoLabel>
                        <textarea
                          disabled={disabledInUpdate}
                          type="text"
                          ref={field.name}
                          rows={field.rows ? field.rows : 6}
                        />
                        {/* <ReachTextEditor /> */}
                      </BasicInfoField>
                    );
                  default:
                }
                return null;
              })}
            </ul>
            <PromptInfo>
              {" "}
              <MandatoryMark>*</MandatoryMark> fields are mandatory.{" "}
            </PromptInfo>
          </BasicInfoContainer>
        ) : null}

        {singleItem && singleItem.mediaFile ? (
          <BasicInfoContainer
            heightPx={singleItem.mediaFile.heightPx}
            widthPercentage={singleItem.mediaFile.widthPercentage}
            bgColor={singleItem.mediaFile.bgColor}
          >
            <BasicInfoTitle>
              {singleItem.mediaFile.title
                ? singleItem.mediaFile.title
                : "Media File"}
            </BasicInfoTitle>
            <UploadBox
              ref={singleItem.mediaFile.name}
              adType={singleItem.mediaFile.fileType}
              uploadingControl={this.uploadingControl}
            />
          </BasicInfoContainer>
        ) : null}

        {singleItem && singleItem.mediaFile1 ? (
          <BasicInfoContainer
            heightPx={singleItem.mediaFile1.heightPx}
            widthPercentage={singleItem.mediaFile1.widthPercentage}
            bgColor={singleItem.mediaFile1.bgColor}
          >
            <BasicInfoTitle>
              {singleItem.mediaFile1.title
                ? singleItem.mediaFile1.title
                : "Media File 1"}
            </BasicInfoTitle>
            <UploadBox
              ref={singleItem.mediaFile1.name}
              adType={singleItem.mediaFile1.fileType}
              uploadingControl={this.uploadingControl}
            />
          </BasicInfoContainer>
        ) : null}

        {singleItem && singleItem.mediaFile2 ? (
          <BasicInfoContainer
            heightPx={singleItem.mediaFile2.heightPx}
            widthPercentage={singleItem.mediaFile2.widthPercentage}
            bgColor={singleItem.mediaFile2.bgColor}
          >
            <BasicInfoTitle>
              {singleItem.mediaFile2.title
                ? singleItem.mediaFile2.title
                : "Media File 2"}
            </BasicInfoTitle>
            <UploadBox
              ref={singleItem.mediaFile2.name}
              adType={singleItem.mediaFile2.fileType}
              uploadingControl={this.uploadingControl}
            />
          </BasicInfoContainer>
        ) : null}

        {singleItem && singleItem.mediaFile3 ? (
          <BasicInfoContainer
            heightPx={singleItem.mediaFile3.heightPx}
            widthPercentage={singleItem.mediaFile3.widthPercentage}
            bgColor={singleItem.mediaFile3.bgColor}
          >
            <BasicInfoTitle>
              {singleItem.mediaFile3.title
                ? singleItem.mediaFile3.title
                : "Media File 3"}
            </BasicInfoTitle>
            <UploadBox
              ref={singleItem.mediaFile3.name}
              adType={singleItem.mediaFile3.fileType}
              uploadingControl={this.uploadingControl}
            />
          </BasicInfoContainer>
        ) : null}

        {singleItem && singleItem.cascadeCollection ? (
          <BasicInfoContainer
            heightPx={singleItem.cascadeCollection.heightPx}
            widthPercentage={singleItem.cascadeCollection.widthPercentage}
            bgColor={singleItem.cascadeCollection.bgColor}
          >
            <BasicInfoTitle>
              {singleItem.cascadeCollection.title
                ? singleItem.cascadeCollection.title
                : "Cascade Selection"}
            </BasicInfoTitle>
            <CascadeCollection
              ref={singleItem.cascadeCollection.name}
              conf={singleItem.cascadeCollection.conf}
            />
          </BasicInfoContainer>
        ) : null}

        <BottomButtonsContainer>
          <BottomButton
            submitChange
            ref={"saveChangeButton"}
            disabled={this.state.saveButtonDisabled}
            onClick={this.saveChange}
          >
            {" "}
            {pageSwitch}{" "}
          </BottomButton>
          { this.state.showSaveAsButton ? <BottomButton
            submitChange
            ref={"saveChangeButton"}
            disabled={this.state.saveButtonDisabled}
            onClick={() => this.saveChange('saveAs')}
          >
            Save As
          </BottomButton> : null }
          <BottomButton cancelChange onClick={this.cancelChange}>
            {" "}
            Cancel{" "}
          </BottomButton>
        </BottomButtonsContainer>
      </React.Fragment>
    );
  }
}
