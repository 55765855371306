import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import store from '../../store';
import { addAsyncReducer } from '../../store';

const StatsItemsContainer = styled.div`
  display: block;
  float: left;
  text-align: right;
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  width: ${props => props.widthPercentage + '%'};
`;

const StatsItem = styled.div`
  color: ${props => theme[props.color]};
  & span {
    margin-left: 5px;
  }
`;

export default class extends React.Component {
  constructor (props) {
    super(props);
    const nameSpace = 'statsItems' + props.queryKeyValue;
    if(!store.getState()[nameSpace]){
      // console.log(nameSpace);
      addAsyncReducer(store, {nameSpace} );
    }
  }

  loadSecSubList = async () => {
    const { endPoint, queryKey, queryKeyValue, defaultQuery } = this.props;
    const res = await axios.get(endPoint + '?_time=0&' + queryKey + '=' + queryKeyValue + defaultQuery);
    const resObjsFiltered = res.data?.objs?.filter( item => item[queryKey] === queryKeyValue);
    resObjsFiltered.length && store.dispatch({ type: 'get_statsItems' + queryKeyValue, data: {...res.data, objs: resObjsFiltered} });
  }


  componentDidMount () {
    this.loadSecSubList();
  }

  render () {
    const { list, statsItems, widthPercentage } = this.props;
    // console.log(list);
    return (
      <StatsItemsContainer widthPercentage={widthPercentage}>
        {list.length > 0 && statsItems.map( (item, index) => {
          const sum = list.reduce( (acc, cur) => acc + cur[item.valueKey], 0);
          const sumCal = list.reduce( (acc, cur) => acc + cur[item.valueKey] * (cur[item.calKey].indexOf(item.calUnit)>-1 ? parseFloat(cur[item.calKey].replace('m2','').replace('m²','').replace('m','')) : 0), 0);
          return (
            <StatsItem key={index} color={item.color}>
              <label>{item.label}</label>
              <span>{sum}</span>
              {sumCal>0 && <span>({sumCal.toFixed(2)} {item.calUnit})</span>}
            </StatsItem>
          )
        })}
      </StatsItemsContainer>
    )
  }
}