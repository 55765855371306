import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import * as restful from '../../api/restful-api';
import moment from 'moment';
import _ from 'lodash';
import { BtnContainer } from '../views/list-body';
import SecondSubList from './second-sub-list';
import StatsItems from './stats-items';
import store from '../../store';

const MainListItem = styled.li`
  padding: 100px 10px 10px 10px;
  margin-bottom: 20px;
  border: 1px solid ${theme.mainLighterColor};
  position: relative;
`;

const RoundCheckContainer = styled.div`
  float: left;
  width: 16px;
  height: 16px;
  background: #ddd;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,.5);
`;
const CheckBoxInput = styled.input`
  visibility: hidden;
  &:checked+label {
    background: ${theme.mainLighterColor};
  }
`;
const RoundCheckLabel = styled.label`
  display: block;
  width: 10px;
  height: 10px;
  transition: all .5s ease;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  background: #d3d3d3;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.5);
`;

const ListImageContainer = styled.div`
  float: left;
  width: ${props => props.widthPercentage + '%'}

  & img {
    border-style: none;
    vertical-align: middle;
    max-width: 50px;
    height: auto;
    display: inline;
  }
`;

const MainListItemHeader = styled.div`
  border-bottom: 1px dashed ${theme.mainLighterColor};
  padding-bottom: 5px;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px;
  left: 0;
`;

const ListCode = styled.div`
  display: block;
  float: left;
  width: ${props => props.widthPercentage + '%'};
  text-align: right;

  & span {
    display: inline-block;
    font-size: 18px;
    padding: 5px;
    background: ${theme.mainColor};
    color: #fff;
    margin-top: 22px;
  }
`;

const ListName = styled.div`
  display: block;
  float: left;
  width: ${props => props.widthPercentage + '%'};

  & span {
    display: inline-block;
    font-size: 18px;
    padding: 5px;
    background: ${theme.mainBgColor};
    color: ${theme.mainColor};
    margin-top: 22px;
  }
`;

const TotalControl = styled.div`
  display: block;
  float: left;
  width: ${props => props.widthPercentage + '%'};
  text-align: right;
  font-size: 16px;
  margin-top: 34px;
`;

const SubTotal = styled.span`
  color: ${ props => props.isMatch ? theme.successColor : theme.failureColor};
`;

const SubListItem = styled.li`
  float: ${ props => props.subListVerticalDisplay ? 'none' : 'left' };
  display: ${ props => props.subListVerticalDisplay ? 'flex' : 'inherit' };
  flex-wrap: ${ props => props.subListVerticalDisplay ? 'wrap' : 'inherit' };
  width: ${ props =>  props.subListVerticalDisplay ? '100%' : (101 - props.itemsPerRow) / props.itemsPerRow + '%' };
  margin-right: ${ props =>  props.subListVerticalDisplay ? '0' : '1%' };
  margin-bottom: ${ props =>  props.subListVerticalDisplay ? '20px' : '10px' };

  &:nth-child(${props => props.itemsPerRow}n) {
    margin-right: 0;
  }

  & div:has(img) {
    width: ${ props =>  props.subListVerticalDisplay ? '20%' : 'auto' };
  }
`;

const SubListImageContainer = styled.div`

  & img {
    margin: auto;
  }
`;

const SubListDateContainer = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.mainDarkColor};
`;

const SubListNumberContainer = styled.div`
  text-align: center;
  font-size: 14px;
  line-height: 36px;
  color: ${theme.mainColor};

  & span:first-child {
    background: ${theme.mainBgColor};
    padding: 5px;
  }

  & span:last-child {
    background: ${theme.mainColor};
    color: #fff;
    padding: 5px;
  }
`;

const InputContainer = styled.div`
  text-align: center;

  & label {
    color: ${theme.mainColor};
    font-size: 14px;
  }
`;

const Input = styled.input`
  width: 50px;
  height: 20px;
  background: none;
  border: none;
  border-bottom: 1px solid ${theme.mainLighterColor};
  font-size: 14px;
  padding: 5px 0 5px 5px;
  color: ${theme.mainDarkColor};
`;

const Button = styled.button`
  background-color: ${theme.mainLighterColor};
  width: 100px;
  height: 38px;
  border: none;
  padding: 0;
  color: #fff;
  margin: auto;
  display: block;

  &:hover {
    background-color: ${theme.mainLightColor};
    transition: background-color .3s;
  }
`;

export default class extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      dataToSubmit: [],
      dataList: props.subList ? props.subList : [],
    }
  }

  handleInputChange = ( params, e) => {
    if(parseInt(e.target.value)>0){
      const obj = { [params.addKey]: params.addKeyValue, [params.valueKey]: parseInt(e.target.value) <= params.maxValue ? parseInt(e.target.value) : params.maxValue };
      const dataToSubmitTemp = _.filter(this.state.dataToSubmit, item => item[params.addKey] !== params.addKeyValue);
      const dataToSubmit = dataToSubmitTemp.concat(obj);
      this.setState({dataToSubmit});
    }else{
      const dataToSubmit = _.filter(this.state.dataToSubmit, item => item[params.addKey] !== params.addKeyValue);
      this.setState({dataToSubmit});
    }
  }

  createSubItem = async (mainListKey, endPoint, subListKey, mainListItem, orderKey) => {
    const restEndPoint = endPoint.replace(':uuid', mainListItem[mainListKey]);
    //console.log(restEndPoint);
    const res = await axios.put(restEndPoint);
    if (res.data.result === 1) {
      const updatedSubItem = mainListItem[subListKey] ? (mainListItem[subListKey] + ',' + res.data.msg) : res.data.msg;
      mainListItem[subListKey] = updatedSubItem;
      const dataListTemp = _.filter(this.state.dataList, item => item[mainListKey] !== mainListItem[mainListKey]);
      let dataList = dataListTemp.concat(mainListItem);
      dataList = _.orderBy(dataList, [orderKey], ['asc']);
      this.setState({dataList});
    } else {
      window.alert(res.data.msg);
    }
  }

  render () {
    let { dataList, dataToSubmit } = this.state;
    //console.log(dataList);
    this.props.conf.totalWidthRatio = 0;
    const { mainListFields, subListKey, subListFields, subListItemPerRow, subTotalValueKey, subListItemMatchKey, creatableEndpoint, createButtonText, orderKey, subListVerticalDisplay, secondSubList } = this.props.conf;
    dataList = orderKey ? _.orderBy(dataList, [orderKey], ['asc']) : dataList;
    mainListFields.map( field => {
      return this.props.conf.totalWidthRatio += field.widthRatio ? field.widthRatio : 0;
    });
    const { totalWidthRatio } = this.props.conf;
    return (
      <ul>
        {dataList.map( mainListItem => {
          mainListItem.subTotal = 0;
          const subList = mainListItem[subListKey] ? (Array.isArray(mainListItem[subListKey]) ? mainListItem[subListKey] : mainListItem[subListKey].split(',')) : [];
          return (
            <MainListItem key={mainListItem[mainListFields[0].valueKey]}>
              <ul className="clearfix">
                {mainListItem[subListKey] && subList.map( subListItem => {
                  const obj = _.filter(dataToSubmit, item => item[subListItemMatchKey] === subListItem[subListFields[0].valueKey]);
                  if(subTotalValueKey && obj[0] && obj[0][subTotalValueKey]) {
                    mainListItem.subTotal += obj[0][subTotalValueKey]
                  }
                  // console.log(subListItem);
                  return (
                    <SubListItem key={subListFields[0].valueKey === 'self' ? subListItem : subListItem[subListFields[0].valueKey]} itemsPerRow={subListItemPerRow ? subListItemPerRow : 5 } subListVerticalDisplay={subListVerticalDisplay}>
                      {subListFields.map( (field, i) => {
                        switch (field.type) {
                          case 'image':
                            const imgSrc = field.valueKey === 'self' ? (restful.getCookie("fileurl") + '/barcode/' + subListItem.substring(0, 6) + '/' + subListItem + '.jpg') : (restful.getCookie("fileurl") + subListItem[field.valueKey]);
                            return (
                              <SubListImageContainer key={i}>{ imgSrc ? <img src={ imgSrc } alt="Item" /> : ''}</SubListImageContainer>
                            );
                          case 'date':
                            return (
                              <SubListDateContainer key={i}><span>{moment(subListItem[field.valueKey]).format('DD/MM/YYYY')}</span></SubListDateContainer>
                            );
                          case 'number':
                            return (
                              <SubListNumberContainer key={i}><span>{field.label}</span><span>{subListItem[field.valueKey]}</span></SubListNumberContainer>
                            );
                          case 'input':
                            return (
                              <InputContainer key={i}>
                                <label>{field.label}</label>
                                <Input
                                  type={field.dataType}
                                  value = { obj.length ? obj[0][subTotalValueKey] : '' }
                                  onChange={this.handleInputChange.bind(null, {
                                    addKey: field.addKey,
                                    addKeyValue: subListItem[field.addValueKey],
                                    valueKey: field.valueKey,
                                    maxValue: subListItem[field.maxKey],
                                  })}
                                />
                              </InputContainer>
                            );
                          default:
                            return (
                              <div hidden={field.hidden} key={i}><span>{subListItem[field.valueKey]}</span></div>
                            );
                        }
                      })}
                      { secondSubList && <SecondSubList mainKey={subListItem} subKey={mainListItem[secondSubList.subKey]} conf={secondSubList}/>}
                    </SubListItem>
                  );
                })}
                { creatableEndpoint ? 
                  <SubListItem key="createNew" itemsPerRow={subListItemPerRow ? subListItemPerRow : 5 }>
                    <Button onClick={this.createSubItem.bind(null, mainListFields[0].valueKey, creatableEndpoint, subListKey, mainListItem, orderKey)}>{createButtonText}</Button>
                  </SubListItem>
                : null}
              </ul>
              <MainListItemHeader className="clearfix">{mainListFields.map( (field, i) => {
                const widthPercentage = field.widthRatio / totalWidthRatio * 100;
                switch (field.type) {
                  case 'checkbox':
                    return (
                      <RoundCheckContainer key={i} hidden={ field.hidden ? field.hidden : false }>
                        <CheckBoxInput
                          type="checkbox"
                          id={`rounded${mainListItem[field.valueKey]}`}
                          onClick={function(event){event.stopPropagation();}}
                        />
                        <RoundCheckLabel id={`dummy${mainListItem[field.valueKey]}`} htmlFor={`rounded${mainListItem[field.valueKey]}`} onClick={function(event){event.stopPropagation();}} />
                      </RoundCheckContainer>
                    );
                  case 'image':
                    let value = mainListItem;
                    field.valueKey.split('.').map( item => {
                      return value = value[item];
                    });
                    return (
                      <ListImageContainer key={i} widthPercentage={widthPercentage}>
                        { value ? <img src={ restful.getCookie("fileurl") + value } alt="Item" /> : ''}
                        { field.addIcon ? <BtnContainer key={i}><button title={field.addTooltip} onClick={this.createSubItem.bind(null, mainListFields[0].valueKey, field.addEndpoint, subListKey, mainListItem, orderKey)}>
                          <svg className={"icon-"+field.addIcon}><use xlinkHref={"#"+field.addIcon} /></svg>
                        </button></BtnContainer> : null }
                      </ListImageContainer>
                    );
                  case 'code':
                    return (
                      <ListCode key={i} widthPercentage={widthPercentage}><span>{mainListItem[field.valueKey]}</span></ListCode>
                    );
                  case 'name':
                    return (
                      <ListName key={i} widthPercentage={widthPercentage}><span>{mainListItem[field.valueKey]}</span></ListName>
                    );
                  case 'totalControl':
                      let subTotal = 0;
                      if (field.matchKey && mainListItem[field.matchKey]) {
                        mainListItem[field.matchKey].map( item => {
                          return subTotal += item[field.matchSubKey];
                        });
                      } else {
                        subTotal = mainListItem.subTotal;
                      }
                      let calSubTotal = 0;
                      let calTotal = 0;
                      if (mainListItem[field.calKey]) {
                        const calUnitValue = parseFloat(mainListItem[field.calKey].replace('m2','').replace('m²','').replace('m',''));
                        calSubTotal = calUnitValue * subTotal;
                        calTotal = calUnitValue * mainListItem[field.valueKey];
                      }
                      return (
                        <TotalControl key={i} widthPercentage={widthPercentage}>
                          <SubTotal isMatch={ subTotal === mainListItem[field.valueKey] ? true : false }>{subTotal}</SubTotal> / {mainListItem[field.valueKey]} {field.suffix}
                          { mainListItem[field.calKey] ?
                          <div>{calSubTotal ? calSubTotal.toFixed(3) : calSubTotal} / {calTotal.toFixed(3)} {field.calUnit}</div>
                          : null }
                        </TotalControl>
                      );
                    case 'stats':
                      return (
                        <StatsItems
                          key={i}
                          list={ store.getState()['statsItems' + mainListItem[field.queryKey]]?.list || [] }
                          queryKeyValue={mainListItem[field.queryKey]}
                          widthPercentage={widthPercentage}
                          {...field}
                        />
                      );
                  default:
                }
                return null;
              })}</MainListItemHeader>
            </MainListItem>
          );
        })}
      </ul>
    );
  }
}